import React from 'react';

import Scoreboard from './Scoreboard';

const leaderBoard = () => (
    <div style={{marginTop:"100px"}}>
        <Scoreboard />
    </div>
);

export default leaderBoard;